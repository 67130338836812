import checklist from '../images/checklist.png';
import dottedCircle from '../images/dotted_circle.png';
import message from '../images/message.png';
import feat0 from '../images/Feature_0.webp';
import feat1 from '../images/Feature_1.webp';
import feat2 from '../images/Feature_2.webp';

import Carousel from 'react-bootstrap/Carousel';
import FeaturesCard from './FeaturesCard';

const FeaturesCarousel = () => {
    return (
        <Carousel className='featureCarousel'>
            <Carousel.Item>
                <FeaturesCard
                    header="Select your interests"
                    body= {
                    <p>
                        Choose what excites you and receive <br></br>
                        personalized career video content tailored <br></br>  
                        to your professional and academic <br></br>
                        interests.
                    </p>
                    }
                    headerImg={checklist}
                    img={feat0}
                />
            </Carousel.Item>

            <Carousel.Item>
                <FeaturesCard
                    header="Enter the Portal"
                    body={
                    <p>
                        See inside the careers you are or should <br></br>
                        be considering through short-form videos <br></br>
                        made by professionals on personalized, <br></br>
                        customizable content feeds.
                    </p>
                    }
                    headerImg={dottedCircle}
                    img={feat1}
                />
            </Carousel.Item>

            <Carousel.Item>
                <FeaturesCard
                    header="Message Professionals"
                    body={
                    <p> 
                        Connect with the video creators to ask <br></br> 
                        them questions about their role, make a  <br></br>
                        professional connection, or set up one <br></br>
                        day shadowing opportunities.
                    </p>
                    }
                    headerImg={message}
                    img={feat2}
                />
            </Carousel.Item>
        </Carousel>
    )
}

export default FeaturesCarousel