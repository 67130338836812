import { Toaster } from 'react-hot-toast';

function AppToaster() {
    return (
        <div>
            <Toaster/>
        </div>
    )
}

export default AppToaster