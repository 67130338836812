import { Stack } from "react-bootstrap";
const CareerCard = (props) => {
    const marginBottom = props.bottom;
    const marginRight = props.right;
    const width = props.width;
    return (
        <Stack style={{width: width, border: '1px solid gray', borderRadius:20, marginBottom:marginBottom, marginRight:marginRight}}>
          <div className='careerHeader' style={{marginTop:40, letterSpacing:1, marginBottom:20}}>
            {props.header}
          </div>
          <div className='careerBody' style={{marginBottom:0}}>
            {props.body}
          </div>
          <button className='ccBtn' style={{marginBottom:30}} onClick={props.onClick}>{props.btnLabel}</button>
        </Stack>
    )
}

export default CareerCard