import { Card, Image, Stack } from 'react-bootstrap';

const FeaturesCard = (props) => {
    return (
        <Card className='featureCard' border='light'>
            <div style={{display:'block', margin:'auto'}}>
            <Stack direction='horizontal' className='featureCardHeader'>
                <Image src={props.headerImg} alt='headerImg'></Image>
                {props.header}
            </Stack>
            </div>
            <p className='featureCardImg'><Card.Img src={props.img} alt='featImg'></Card.Img></p>
            <Card.Body className='featureCardBody' style={{marginBottom:'5%'}}>{props.body}</Card.Body>
        </Card>
    )
}

export default FeaturesCard